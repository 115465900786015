import React from 'react'
import { TextPhoneInputField } from '../../../components'

const PopupComponent = ({ defaultCountry }) => (
  <TextPhoneInputField
    name='called_phone'
    defaultCountry={defaultCountry}
    style={{ marginTop: 16 }}
    required
    autoFocus
  />
)

export default React.memo(PopupComponent)
import React, { useCallback, useState } from 'react'
import { hooks } from '@front/volcanion'

import { Grid, IconButton } from '@mui/material'
import { Close, DeleteOutlined } from '@mui/icons-material'

import { TableAction } from '../..'

const DeleteRowAction = ({ name, onClick, disableConfirm, iconProps, ...props }) => {
  const [isConfirm, setConfirm] = useState(false)
  const [, { openDialog }] = hooks.useDialog(name)
  const [values, setSectionValues, { removeSection, ...rest }] = hooks.useSection(hooks.useSectionPath())
  const onConfirmChange = useCallback(() => disableConfirm ? onDelete() : setConfirm(!isConfirm), [disableConfirm, isConfirm, setConfirm, onDelete])

  const onDelete = useCallback(async () => {
    await onClick()
    removeSection()
  }, [onClick])

  const isFormReadOnly = hooks.useFormStatus('isReadOnly')

  if (!!isFormReadOnly)
    return null

  return <>
    <Grid container justifyContent='center' spacing={1}>
      <Grid item xs hidden={!!isConfirm}>
        <IconButton {...iconProps} sx={{ color: 'primary.main', fontSize: 33 }} onClick={onConfirmChange}>
          <DeleteOutlined />
        </IconButton>
      </Grid>
      <Grid item xs={4} hidden={!isConfirm}>
        <TableAction
          icon='check'
          iconProps={{ color: 'success' }}
          onClick={onDelete}
          ButtonComponent={IconButton}
          {...props}
        />
      </Grid>
      <Grid item xs={4} hidden={!isConfirm}>
        <IconButton onClick={onConfirmChange}>
          <Close color='error' />
        </IconButton>
      </Grid>
    </Grid>
  </>
}

export default React.memo(DeleteRowAction)
import React, { useCallback } from 'react'

import { hooks } from '@front/volcanion'

import _ from 'lodash'

import Component from './component'

const Container = ({ order_id, origin, target, ...rest }) => {
  const [, { openDialog: onClick }] = hooks.useDialog('call_number')
  const [{ label, disabled }] = hooks.useCall(order_id, target, origin)
  const [called_phone] = hooks.useActiveUser({ populate: ['auth'], mutator: 'auth.gsm' })

  const mergedProps = {
    called_phone,
    disabled,
    label,
    order_id,
    target,
    origin,
    onClick
  }

  return <Component {...mergedProps} {...rest} />
}

export default Container

import React from 'react'

import { Button, Typography } from '@mui/material'

import CallDialog from '../Dialog'

const CallButtonComponent = ({
  called_phone,
  disabled,
  label,
  order_id,
  target,
  origin,
  onClick,
  _button,
  _label
}) => {
  return (
    <>
      <CallDialog called_phone={called_phone} origin={origin} target={target} order_id={order_id} />
      {!disabled && (
        <Button variant='contained' onClick={() => onClick('call_number')} {..._button}>
          <Typography {..._label}>{label}</Typography>
        </Button>
      )}
    </>
  )
}

export default CallButtonComponent

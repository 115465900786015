export default {
  table: {
    empty: "Aucun résultat disponible"
  },
  dialog: {
    export: {
      title: "Exporter",
      description: "Sélectionner un template pour exporter"
    }
  },
  page: {
    rowPerPage: "Éléments par page",
    displayed_rows: "{{from}}-{{to}} sur {{count}}"
  },
  datetime: {
    disablePast: "Date dans le passé",
    invalidDate: "Date invalide",
    minTime: "Heure dans le passé",
    minDate: "Éditer ce champ pour voir les dates disponibles"
  },
  date: {
    disablePast: "Date dans le passé",
    invalidDate: "Date invalide"
  },
  time: {
    minTime: "Heure dans le passé",
    invalidDate: "Date invalide"
  },
  driver: {
    info: "Informations du chauffeur :"
  },
  vehicle: {
    label: {
      one: "Véhicule :"
    },
    status: {
      available: "Disponible",
      unavailable: "Indisponible",
      in_use: "En cours",
      en_route: "En route",
      reserved: "Réservée"
    },
    info: "Informations du véhicule :"
  },
  order: {
    info: "Informations de la course :"
  },
  dispatch: {
    label: {
      one: "Dispatch :"
    },
    action: "Dispatcher"
  },
  call: {
    submit_success: 'Veuillez patientez, vous allez recevoir un appel',
    central: {
      title: 'Appel central',
      description: "Vous allez recevoir un appel de mise en relation avec un téléopérateur sur le numéro suivant:"
    },
    driver: {
      title: 'Appel chauffeur',
      description: 'Vous allez recevoir un appel de mise en relation avec le chauffeur sur le numéro suivant:'
    },
    customer: {
      title: 'Appel client',
      description: "Vous allez recevoir un appel de mise en relation avec le client sur le numéro suivant:"
    },
  },
  action: {
    cancel: 'Annuler'
  },
  error: {
    default: "Une erreur est survenue"
  },
}

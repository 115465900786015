import React, { useCallback } from 'react'
import { hooks, I18n } from '@front/volcanion'

import { DialogContent, FormDialog } from '../../..'
import Callbacks from './callbacks'
import CallDialogContent from './container'

const CallButtonComponent = ({ target, order_id, origin, called_phone }) => {
  const [, { onCall }] = hooks.useCall(order_id, target, origin)
  const handleCallNumber = useCallback(Callbacks.handleCallNumberlHandler(onCall), [onCall])
  const getSuccessNotification = useCallback(Callbacks.getSuccessNotificationHandler(), [])
  const getFailedNotification = useCallback(Callbacks.getFailedNotificationHandler(), [])

  return (
    <FormDialog
      name={'call_number'}
      title={I18n.t(`sqr.call.${target}.title`)}
      description={I18n.t(`sqr.call.${target}.description`)}
      formProps={{
        onSubmit: handleCallNumber,
        initialValues: { called_phone },
        getSuccessNotification,
        getFailedNotification,
        submitOnEnter: true,
      }}
      cancelLabel={I18n.t('sqr.action.cancel')}
    >
      <DialogContent>
        <CallDialogContent />
      </DialogContent>
    </FormDialog>
  )
}

export default CallButtonComponent


import React from 'react'

import { hooks } from '@front/volcanion'
import { HookUtils } from '@front/volcanion/utils'

import Component from './component'
import { Loader } from '../../../components'

const Container = props => {
  const [defaultCountry, , countryState] = hooks.useSetting('country_code')

  const mergedProps = {
    defaultCountry
  }
  return (
    <Loader isLoading={HookUtils.getLoadingState([countryState])}>
      <Component {...mergedProps} />
    </Loader>
  )
}

export default Container

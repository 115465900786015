import { I18n } from '@front/volcanion'

class Callbacks {
  static handleCallNumberlHandler(onCall) {
    return async function handleCallNumber(data, extra, meta, state) {
      await onCall(data?.called_phone)
    }
  }
  static getSuccessNotificationHandler() {
    return function getSuccessNotification() {
      return [I18n.t('sqr.call.submit_success'), { variant: 'success' }]
    }
  }
  static getFailedNotificationHandler() {
    return function getFailedNotification() {
      return [I18n.t('sqr.error.default'), { variant: 'error' }]
    }
  }
}

export default Callbacks

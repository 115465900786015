import { hooks } from '@front/volcanion'

const useDisplayExport = () => {
  const EXPORT_ENABLED = hooks.useRelayFeature('EXPORT_ENABLED')
  const current_path = hooks.usePathname()
  const authorization = hooks.useCurrentAuthorization()
  const [templates, , { isLoading }] = hooks.useModelSearch('template', 'get', { initial_filter: { page: { path: current_path } } })
  const display = EXPORT_ENABLED && !!authorization?.export && templates.length > 0

  return [display, isLoading]
}

export default useDisplayExport
